import i18n from "@/i18n";

export const equipmentOptions = [
  {
    text: i18n.t("capacity.equipment.forklift"),
    value: "forklift",
  },
  {
    text: i18n.t("capacity.equipment.liftcrane"),
    value: "liftcrane",
  },
  {
    text: i18n.t("capacity.equipment.loadingramp"),
    value: "loadingramp",
  },
  {
    text: i18n.t("capacity.equipment.liftinglimb"),
    value: "liftinglimb",
  },
  {
    text: i18n.t("capacity.equipment.other"),
    value: "other",
  },
];

export const featureOptions = [
  {
    text: i18n.t("capacity.feature.fridge"),
    value: "fridge",
  },
  {
    text: i18n.t("capacity.feature.cooler"),
    value: "cooler",
  },
  {
    text: i18n.t("capacity.feature.adr"),
    value: "adr",
  },
  {
    text: i18n.t("capacity.feature.kapell"),
    value: "kapell",
  },
];

export default {};
