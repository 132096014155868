import Vue from "vue";

export const getCompanyList = (key, value) => {
  return Vue.axios.get("/api/company/list/" + key + "/" + value, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCompany = (id) => {
  return Vue.axios.get("/api/agreement/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};