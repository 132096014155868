/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/store/index.js";
//import autosize from "autosize";
//import { gmapApi } from "vue2-google-maps";
//import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
import { required, decimal, numeric } from "vuelidate/lib/validators";
import { createHelpers } from "vuex-map-fields";
import { formatWithUnit, vehicleOptions } from "@/utils";
const { mapFields } = createHelpers({
  getterType: "needCapacity/getStructureField",
  mutationType: "needCapacity/updateStructureField",
});
//import FormGroup from "@/components/formgroup/formgroup.vue";
import { saveCapacity, saveImportedCapacity } from "@/services/api/capacity";
import { particlesSetup, styleMapSetup } from "@/utils/setup";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BCollapse,
  BLink,
  BSkeleton,
  BSkeletonWrapper,
  BInputGroupText,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTimepicker,
  //BIconThreeDotsVertical,
  BFormRadioGroup,
  BFormRadio,
  BModal,
  BFormFile,
  BTable,
} from "bootstrap-vue";
import ExpireDate from "@/components/expiredate/ExpireDate";
import { convertXmlToJSON } from "@/services/api/import";
import { extractNumberfromLength, extractNumberfromWeight } from "@/utils";
import { getCapacity } from "@/services/api/capacity";
import { lightFormat, parseISO, addDays} from "date-fns";
import { getGooglePlace } from "@/services/api/googleplace";
import AgreementForm from "@/components/agreementform/agreementform";
import { getZipCode } from "@/services/api/geonames";

//import VueJsonPretty from "vue-json-pretty";

//import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "need-capacity-form",
  props: {
    editMode: {
      type: Boolean,
      default: true,
    },
    capacityShow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      isSending: false,
      profileSlideInOpen: false, // move to Vuex store later...
      trackingSlideInOpen: false, // move to Vuex store later...
      showOverlay: false,
      modalOpen: "",
      modalSummaryShow: false,
      particlesSetup: particlesSetup,
      styleMapSetup: styleMapSetup,
      loadingHits: true,
      editModeState: false,
      selectedSizeUnit: "lhw",
      sizeUnitGroups: [
        { text: "L  B  h", value: "lhw" },
        { text: "Kubikk", value: "m3" },
      ],
      autoCompleteRestrictions: {
        componentRestrictions: { country: ["no"] }, //, "se", "dk", "fi"]
      }, // , "de"
      volumeTabIndex: 0,
      form: {
        id: "",
        title: "",
        weight: "",
        height: "",
        length: "",
        width: "",
        volume: "",
        package: [],
        selectedFeature: [], // Must be an array reference!
        selectedEquipment: [], // Must be an array reference!
        equipmentOther: "",
        address: "",
        addressDest: "",
        addressObj: {},
        addressDestObj: {},
        shortDescription: "",
        pickup1Date: "",
        pickup1Time: "",
        pickup2Date: "",
        pickup2Time: "",
        delivery1Date: "",
        delivery1Time: "",
        delivery2Date: "",
        delivery2Time: "",
        expiresAt: "",
        splitRoute: "0",
        companyId: "",
        categoryId: "",
        status: 0,
      },
      hasImage: false,
      hasExpireTime: "0",
      defaultPickupTime: "12:00",
      defaultDeliveryTime: "16:00",
      formSummary: {},
      showAgreement: false,
      featureOptions: [
        {
          text: this.$i18n.t("capacity.feature.fridge"),
          value: "fridge",
        },
        {
          text: this.$i18n.t("capacity.feature.cooler"),
          value: "cooler",
        },
        {
          text: this.$i18n.t("capacity.feature.adr"),
          value: "adr",
        },
        {
          text: this.$i18n.t("capacity.feature.kapell"),
          value: "kapell",
        },
        {
          text: this.$i18n.t("capacity.feature.sideopen"),
          value: "sideopen",
        },
      ],
      equipmentOptions: [
        {
          text: this.$i18n.t("capacity.equipment.forklift"),
          value: "forklift",
        },
        {
          text: this.$i18n.t("capacity.equipment.liftcrane"),
          value: "liftcrane",
        },
        {
          text: this.$i18n.t("capacity.equipment.loadingramp"),
          value: "loadingramp",
        },
        {
          text: this.$i18n.t("capacity.equipment.liftinglimb"),
          value: "liftinglimb",
        },
        {
          text: this.$i18n.t("capacity.equipment.other"),
          value: "other",
        },
      ],
      showImportFileModal: false,
      file1: null,
      importResult: [],
      importFields: [
        { key: "address", label: "Fra" },
        { key: "addressDest", label: "Til" },
        { key: "capacity", label: "Kolli" },
      ],
    };
  },
  validations: {
    form: {
      address: { required },
      addressDest: { required },
      //weight: { required, decimal },
      //volume: { decimal },
      //height: { required, decimal },
      //length: { required, decimal },
      package: { required },
      //width: { required, decimal },
    },
    //TODO: Add validation for hasExpireTime && form.expiresAt (combined)
  },
  components: {
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-textarea": BFormTextarea,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-collapse": BCollapse,
    "b-link": BLink,
    "b-skeleton": BSkeleton,
    "b-skeleton-wrapper": BSkeletonWrapper,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText,
    "b-form-datepicker": BFormDatepicker,
    "b-form-timepicker": BFormTimepicker,
    "b-form-radio-group": BFormRadioGroup,
    "b-form-radio": BFormRadio,
    "b-modal": BModal,
    "b-form-file": BFormFile,
    "b-table": BTable,
    "agreement-form": AgreementForm,
    //FormGroup
    "expire-date": ExpireDate
  },
  i18n: {
    /* messages: {
        en: {"tr": i18nCodes_en},
        no: {"tr": i18nCodes_no}
      }  */
  },
  created() {
    console.info(
      "CREATED assignment form",
      this.$store.getters["needCapacity/capacityForm"]
    );
    if (
      this.editMode &&
      (!this.capacityObject ||
        this.capacityObject === null ||
        this.capacityObject === "")
    ) {
      this.form = this.$store.getters["needCapacity/capacityForm"];
    }
  },
  mounted() {
    if (typeof this.form === "undefined") this.initFormObject();

    console.info("Mounted:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);

    if (this.form && this.form.address && this.$refs.autoCompleteFrom)
      this.$refs.autoCompleteFrom.$refs.input.value = this.form.address;
    if (this.form && this.form.addressDest && this.$refs.autoCompleteDest)
      this.$refs.autoCompleteDest.$refs.input.value = this.form.addressDest;
  },
  watch: {
    $route(to, from) {
      // Call resizePreserveCenter() on all maps
      //Vue.$gmapDefaultResizeBus.$emit("resize");
    },
    form: {
      handler: function (newValue) {
        console.info("Form updated");
        if (this.$store && (!newValue.id || newValue.id === "")) {
          this.$store.dispatch("needCapacity/storeForm", newValue);
        }
      },
      deep: true,
      immediate: false,
    },
    capacityObject: {
      handler: async function (newValue) {
        if (newValue && newValue !== "" && newValue[0] === "{") {
          const obj = JSON.parse(newValue);
          // TODO: update this.form.  with new data...
          if (obj.id && obj.id !== "") {
            this.loading = true;
            let capacity = await this.getCapacity(obj.id);
            this.initFormObject(capacity.data);
            this.loading = false;
            this.$emit("changed-conversations", capacity.data.conversations);
          }
        }
      },
      immediate: true,
    },
    /*editing: {
      handler: function(newValue, oldValue) {
        debugger;
        if (this.form && this.form.address && this.$refs.autoCompleteFrom)
      this.$refs.autoCompleteFrom.$refs.input.value = this.form.address;
    if (this.form && this.form.addressDest && this.$refs.autoCompleteDest)
      this.$refs.autoCompleteDest.$refs.input.value = this.form.addressDest;
      }
    }*/
  },
  computed: {
    //google: gmapApi,
    //...mapFields(["needCapacity"]), //.title", "form.weight"]),
    id: () => {
      return this.$route.params.id;
    },
    editing() {
      return (
        (this.editMode === true || this.editModeState === true) &&
        this.loading === false
      );
    },
    capacityObject() {
      return this.capacityShow === null || this.capacityShow === ""
        ? ""
        : JSON.stringify(this.capacityShow);
    },
    canEdit() {
      return this.form.companyId === this.$store.getters["account/companyId"];
    },
    expiresAtOptipons() {
      let options = [];
      if (this.form.pickup1Date && this.form.pickup1Date !== "") {
        options.push({
          text: this.form.pickup1Date + " " + (this.form.pickup1Time || this.defaultPickupTime),
          value:
            this.form.pickup1Date +
            "T" +
            (this.form.pickup1Time && this.form.pickup1Time !== "" ? this.form.pickup1Time : this.defaultPickupTime),
        });
      }
      if (this.form.pickup2Date && this.form.pickup2Date !== "") {
        options.push({
          text: this.form.pickup2Date + " " + (this.form.pickup2Time || this.defaultPickupTime),
          value:
            this.form.pickup2Date +
            "T" +
            (this.form.pickup2Time && this.form.pickup2Time !== "" ? this.form.pickup2Time : this.defaultPickupTime),
        });
      }
      if (this.form.delivery1Date && this.form.delivery1Date !== "") {
        options.push({
          text: this.form.delivery1Date + " " + (this.form.delivery1Time || this.defaultDeliveryTime),
          value:
            this.form.delivery1Date +
            "T" +
            (this.form.delivery1Time && this.form.delivery1Time !== ""
              ? this.form.delivery1Time
              : this.defaultDeliveryTime),
        });
      }
      if (this.form.delivery2Date && this.form.delivery2Date !== "") {
        options.push({
          text: this.form.delivery2Date + " " + (this.form.delivery2Time || this.defaultDeliveryTime),
          value:
            this.form.delivery2Date +
            "T" +
            (this.form.delivery2Time && this.form.delivery2Time !== ""
              ? this.form.delivery2Time
              : this.defaultDeliveryTime),
        });
      }
      return options;
    },
    getVolumeWeight() {
      console.info("getVolumeWeight");
      let volumeIndex = 0;
      if (!this.form.package) this.form.package = [];

      this.form.package.forEach((colli) => {
        if (colli.length + colli.width + colli.height != "") {
          try {
            volumeIndex +=
              (Number(colli.length) *
                Number(colli.width) *
                Number(colli.height)) /
              3500;
          } catch (e) {
            console.info("Colli measures are not all numbers");
          }
        }
      });

      return volumeIndex;
    },
  },
  methods: {
    formatWithUnit,
    initFormObject: function (capacity) {
      this.form = {
        id: capacity ? capacity.id ?? "" : "",
        title: capacity ? capacity.title ?? "" : "",
        weight: capacity ? capacity.weight ?? "" : "",
        height: capacity ? capacity.height ?? "" : "",
        length: capacity ? capacity.length ?? "" : "",
        width: capacity ? capacity.width ?? "" : "",
        volume: capacity ? capacity.volume ?? "" : "",
        package: capacity ? capacity.capacity ?? [] : [],
        selectedFeature: capacity ? capacity.selectedFeature ?? [] : [], // Must be an array reference!
        selectedEquipment: capacity
          ? capacity.selectedEquipmentPickup ?? []
          : [], // Must be an array reference!
        equipmentOther: capacity ? capacity.equipmentOtherPickup ?? "" : "",
        address:
          capacity && capacity.addressPickup
            ? capacity.addressPickup.addressLine ?? ""
            : "",
        addressDest:
          capacity && capacity.addressDestination
            ? capacity.addressDestination.addressLine ?? ""
            : "",
        addressObj:
          capacity && capacity.addressPickup ? capacity.addressPickup : {},
        addressDestObj:
          capacity && capacity.addressDestination
            ? capacity.addressDestination
            : {},
        shortDescription: capacity ? capacity.shortDescription ?? "" : "",
        pickup1Date: capacity ? capacity.pickupDate ?? "" : "",
        pickup1Time: capacity ? capacity.pickupTime ?? "" : "",
        pickup2Date: capacity ? capacity.pickupDateLatest ?? "" : "",
        pickup2Time: capacity ? capacity.pickupTimeLatest ?? "" : "",
        delivery1Date: capacity ? capacity.deliveryDate ?? "" : "",
        delivery1Time: capacity ? capacity.deliveryTime ?? "" : "",
        delivery2Date: capacity ? capacity.deliveryDateLatest ?? "" : "",
        delivery2Time: capacity ? capacity.deliveryTimeLatest ?? "" : "",
        expiresAt: capacity ? capacity.expiresAt ?? "" : "",
        splitRoute: capacity ? capacity.splitRoute ?? "" : "0",
        status: capacity ? capacity.status ?? 10 : 10,
        type: capacity ? capacity.type ?? 2 : 2,
        companyId: capacity && capacity.company ? capacity.company.id : null,
        company: capacity ? capacity.company ?? null : null,
      };
      this.hasExpireTime = this.form.expiresAt !== "" ? "1" : "0";
    },
    saveCapacity,
    saveImportedCapacity,
    getCapacity,
    lightFormat,
    parseISO,
    addDays,
    getGooglePlace,
    getZipCode,
    formatVolumeWeight(volumeWeight) {
      return volumeWeight && volumeWeight > 0
        ? " " + volumeWeight + " dm<sup>3</sup>/l"
        : "";
    },
    setEditMode: function () {
      this.$emit("toggle-editing");
      this.editModeState = true;
      this.$nextTick(() => {
        if (this.form && this.form.address && this.$refs.autoCompleteFrom)
          this.$refs.autoCompleteFrom.$refs.input.value = this.form.address;
        if (this.form && this.form.addressDest && this.$refs.autoCompleteDest)
          this.$refs.autoCompleteDest.$refs.input.value = this.form.addressDest;
      });
    },
    importFile: function () {
      this.showImportFileModal = true;
    },
    readFile: function (event) {
      var input = event.target;
      var text = "";
      var reader = new FileReader();
      var that = this;
      var onload = async function (event) {
        text = reader.result;
        console.log(text);
        //that.importResult = text;

        let result = await convertXmlToJSON(text);
        //that.importResult = JSON.stringify(result.data.transfer);

        // Start mapping fields from file to DeliverIt Capacity
        let capacityData = that.mapFieldsToCapacity(result.data.transfer);
        that.importResult = capacityData; //JSON.stringify(capacityData);
      };

      reader.onload = onload;
      reader.readAsText(input.files[0]);
    },
    mapFieldsToCapacity: function (data) {
      //...
      let capacityArr = [];
      data.order.forEach((order) => {
        let capacity = {};
        console.info("Order ", order);
        capacity.shortDescription =
          order.info[0].message[0] +
          " - " +
          order.info[0].sendersInstruction[0];
        capacity.externalRef = order.identification[0].internalOrderId[0];
        capacity.pickup1Date = order.shipment[0].earliestPickupTime[0];
        capacity.delivery2Date = order.shipment[0].latestDeliveryTime[0];
        let address = order.shipment[0].address;
        if (address && address.length > 0) {
          let addressPickup = address.find(
            (address) => address.$.type === "PICKUP"
          );
          if (addressPickup) {
            capacity.address =
              addressPickup.streetAddress[0] +
              ", " +
              addressPickup.zipCode[0] +
              " " +
              addressPickup.city[0];
            capacity.addressObj = {};
            capacity.addressObj.zipCode = addressPickup.zipCode[0];
            capacity.addressObj.city = addressPickup.city[0];
          }

          let addressDest = address.find(
            (address) => address.$.type === "DELIVERY"
          );
          if (addressDest) {
            capacity.addressDest =
              addressDest.streetAddress[0] +
              ", " +
              addressDest.zipCode[0] +
              " " +
              addressDest.city[0];
            capacity.addressDestObj = {};
            capacity.addressDestObj.zipCode = addressDest.zipCode[0];
            capacity.addressDestObj.city = addressDest.city[0];
          }

          // Packages /content
          let loadMeter = 0;
          let totalWeight = 0;
          if (order.orderData && order.orderData.length > 0) {
            loadMeter = order.orderData[0].loadMeter[0];
            if (loadMeter && loadMeter !== "") {
              loadMeter = extractNumberfromLength(loadMeter);
            }
            totalWeight = order.orderData[0].weight[0];
            if (totalWeight && totalWeight !== "") {
              totalWeight = extractNumberfromWeight(totalWeight);
            }
          }

          capacity.capacity = [];
          if (order.package && order.package.length > 0) {
            order.package.forEach((pack) => {
              let loadMeter = extractNumberfromLength(
                pack.loadMeter && pack.loadMeter[0]
              );
              let weight = extractNumberfromWeight(
                pack.weight && pack.weight[0]
              );
              let width = extractNumberfromLength(pack.width && pack.width[0]);
              let height = extractNumberfromLength(
                pack.height && pack.height[0]
              );

              capacity.capacity.push({
                length: loadMeter,
                weight: weight,
                width: width,
                height: height,
                goodsType: pack.goodsType && pack.goodsType[0],
                packageType: pack.packageType && pack.packageType[0],
                stackable: pack.stackable && pack.stackable[0],
              });
            });
          } else {
            if (order.orderData && order.orderData.length > 0) {
              capacity.capacity.push({
                length: loadMeter,
                weight: totalWeight,
              });
            }
          }
        }
        capacity.status = 10;
        capacityArr.push(capacity);
      });
      return capacityArr;
    },
    saveImportedData: function () {
      if (this.importResult && this.importResult.length) {
        let toServer = {};
        toServer.data = this.importResult;
        toServer.type = 2;
        toServer.companyId = this.getCurrentComanyId();

        this.saveImportedCapacity(this.importResult);
      }
    },
    getCurrentComanyId: function () {
      return store.getters &&
        store.getters["account/companyId"] &&
        store.getters["account/companyId"] !== ""
        ? store.getters["account/companyId"]
        : this.companyId && this.companyId != ""
        ? this.companyId
        : "5d658725faa99c2bf4a84eb5";
    },
    openAgreement: function (id) {
      this.showAgreement = true;
    },
    getAddressData: async function(addressData) {
      console.info("From AutoCompl FROM: ", addressData);
      debugger;
      let googlePostalCode = "";

      if (addressData.address_components) {
        for (var i = 0; i < addressData.address_components.length; i++) {
          for (
            var j = 0;
            j < addressData.address_components[i].types.length;
            j++
          ) {
            if (addressData.address_components[i].types[j] == "postal_code") {
              googlePostalCode = addressData.address_components[i].long_name;
            }
          }
        }
      }
      if(!this.form) this.initFormObject();
      this.form.address = addressData.formatted_address || addressData.name;

      if(!googlePostalCode || googlePostalCode == null) {
        googlePostalCode = await getZipCode(this.form.address);
      }

      this.$set(this.form, "addressObj", {
        geometry: addressData.geometry,
        zipCode: googlePostalCode,
        placeId: addressData.place_id,
      });

      console.info("ADDRESS FROM: ", this.form.addressObj);
      /* this.center = {
        lat: addressData.geometry.location.lat(),
        lng: addressData.geometry.location.lng(),
      };
      this.markers = [
        {
          position: {
            lat: addressData.geometry.location.lat(),
            lng: addressData.geometry.location.lng(),
          },
        },
      ];*/
    },
    getAddressDataDest: async function (addressData) {
      console.info("From AUtoCompl DEST: ", addressData);
      let googlePostalCode = "";

      if (addressData.address_components) {
        for (var i = 0; i < addressData.address_components.length; i++) {
          for (
            var j = 0;
            j < addressData.address_components[i].types.length;
            j++
          ) {
            if (addressData.address_components[i].types[j] == "postal_code") {
              googlePostalCode = addressData.address_components[i].long_name;
            }
          }
        }
      }
      this.form.addressDest = addressData.formatted_address || addressData.name;

      if(!googlePostalCode || googlePostalCode == null) {
        googlePostalCode = await getZipCode(this.form.addressDest);
      }

      this.$set(this.form, "addressDestObj", {
        geometry: addressData.geometry,
        zipCode: googlePostalCode,
        placeId: addressData.place_id,
      });
    
      console.info("ADDRESS DEST: ", this.form.addressDestObj);
      /* this.form.addressDest = addressData.formatted_address || addressData.name;
      this.form.addressDestObj = addressData;
      this.centerDest = {
        lat: addressData.geometry.location.lat(),
        lng: addressData.geometry.location.lng(),
      };
      this.markersDest = [
        {
          position: {
            lat: addressData.geometry.location.lat(),
            lng: addressData.geometry.location.lng(),
          },
          icon: "/img/marker-green.png",
        },
      ]; */
    },
    addPackage() {
      debugger;
      if (!this.form.package) this.form.package = [];
      this.form.package.push({
        length: this.form.length,
        width: this.form.width,
        height: this.form.height,
        weight: this.form.weight,
        selectedSizeUnit: this.selectedSizeUnit,
      });
      this.form.length = "";
      this.form.width = "";
      this.form.height = "";
      this.form.weight = "";
    },
    handleDeletePackage(index) {
      if (this.form.package && this.form.package.length > 0) {
        this.form.package.splice(index, 1);
      }
    },
    tabLinkClass(idx) {
      if (this.volumeTabIndex === idx) {
        return ["bg-bluegrey", "text-light"];
      } else {
        return ["bg-lightgrey", "text-dark"];
      }
    },
    dateDisabledFrom(ymd, date) {
      return false;
    },

    recaptcha() {
      this.$recaptcha("submit_assignment").then((token) => {
        console.info("reCAPTCHA token: ", token); // Will print the token
      });
    },
    resetForm() {
      store.dispatch("capacity/resetForm");
      this.initFormObject();
    },
    validate() {
      this.$v.form.$touch();
    },
    submit(event, newStatus, closeModal) {
      // First CHECK ReCAPTCHA V3
      //this.recaptcha();

      console.info("Submitting now...?", this.form);
      this.isSending = true;

      //Check for unadded package
      if (
        this.form.length !== "" ||
        this.form.width !== "" ||
        this.form.height !== "" ||
        this.form.weight !== ""
      ) {
        this.addPackage();
      }

      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) {
        console.info("Pending or Error: ", this.$v.form);
        this.isSending = false;
        return;
      }
      // to form submit after this
      let toServer = {};
      if (this.form.id && this.form.id !== "") toServer.id = this.form.id;
      toServer.title = this.form.title;
      toServer.weight = this.form.weight;
      toServer.height = this.form.height;
      toServer.length = this.form.length;
      toServer.width = this.form.width;
      toServer.volume = this.form.volume;
      toServer.capacity = this.form.package;
      toServer.selectedFeature = this.form.selectedFeature;
      toServer.selectedEquipment = this.form.selectedEquipment;
      toServer.equipmentOther = this.form.equipmentOther;
      toServer.address = this.form.address;
      toServer.addressDest = this.form.addressDest;
      toServer.addressObj = this.form.addressObj;
      toServer.addressDestObj = this.form.addressDestObj;
      toServer.shortDescription = this.form.shortDescription;
      toServer.pickup1Date = this.form.pickup1Date;
      toServer.pickup1Time = this.form.pickup1Time;
      toServer.pickup2Date = this.form.pickup2Date;
      toServer.pickup2Time = this.form.pickup2Time;
      toServer.delivery1Date = this.form.delivery1Date;
      toServer.delivery1Time = this.form.delivery1Time;
      toServer.delivery2Date = this.form.delivery2Date;
      toServer.delivery2Time = this.form.delivery2Time;
      toServer.expiresAt =
        this.hasExpireTime === "1" ? this.form.expiresAt : "";
      toServer.splitRoute = this.form.splitRoute;
      toServer.categoryId =
        this.form.categoryId === "" ? null : this.form.categoryId;
      toServer.companyId = this.getCurrentComanyId();
      toServer.status =
        typeof newStatus !== "undefined"
          ? parseInt(newStatus, 10)
          : this.form.status ?? 10;
      toServer.type = 2; // "request";

      //var that = this;
      this.saveCapacity(toServer, "me")
        .then((response) => {
          console.info("OK", response);
          //this.resetFormdata(response);
          this.form.id = response.data.id;

          if (!toServer.id) {
            this.$noty.success(
              this.$i18n.t("assignment.messages.onSuccessfulSent")
            );
            this.$store.dispatch("needCapacity/resetForm");
            this.form.id = response.data.id;
          } else {
            this.$noty.success(
              this.$i18n.t("assignment.messages.onSuccessfulSave")
            );
          }
          // Emit data back to CapacityList / "My List"
          this.$emit("capacity-saved", response.data);
          this.isSending = false;
        })
        .catch((error) => {
          console.info("ERROR", error);
          this.$noty.info(
            "Noe gikk galt ved registrering av oppdraget ... mer info..."
          );
          this.isSending = false;
        });

      console.info("Submitting this : ", toServer);
    },
  },
};
