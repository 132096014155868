<template>
  <div class="chat-container">
    <div class="heading">
      <h1>{{ title }}</h1>
    </div>
    <div class="body">
      <div class="table" v-if="channelId != null && channelId.length > 1">
        <chat-log
          :channelId="channelId"
          :channelHistory="channelHistory"
        ></chat-log>
        <message-input
          :channelId="channelId"
          :owner="owner"
          :company="company"
        ></message-input>
      </div>
    </div>
  </div>
</template>

<script>
import ChatLog from "@/components/chat/ChatLog";
import MessageInput from "@/components/chat/MessageInput";
import PubNubVue from "pubnub-vue";
//import { mapGetters } from "vuex";

/*function _fetchHistoryOLD(store, channelId) {
  debugger;
  PubNubVue.getInstance().history(
    {
      channel: channelId,
      count: 100, // how many items to fetch
      stringifiedTimeToken: true, // false is the default
    },
    function (_status, response) {
      const msgs = response.messages;
      console.info("FRom PubNub: ", msgs);
      let historyToDisplay = msgs.map((msg) => {
        return msg.entry;
      });
      return historyToDisplay;
      // Iterate msgs array and save each element to history
      /*msgs.forEach((elem) => {
        store.commit("addHistory", { history: [elem] });
      });* /
    }
  );
}
*/

export default {
  name: "chat-container",
  components: {
    ChatLog,
    MessageInput,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    partnerCompany: {
      type: String,
      required: false,
    },
    capacityId: {
      type: String,
      required: false,
    },
    channelId: {
      type: String,
      required: false,
    },
    owner: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      pubnub: null,
      channels: [],
      selectedChannel: "",
      conversations: [],
      currentConversation: {},
      currentChannelId: "",
      channelHistory: [],
    };
  },
  watch: {
    channelId: function (newChannel) {
      this.$nextTick(() => {
        this.fetchHistory(this.$store, newChannel);
      });
    },
  },
  computed: {
    title() {
      return this.owner === true ? this.partnerCompany : this.company.name;
    },
    userUUID() {
      return this.$store.getters["user/chatUUID"];
    },
  },
  methods: {
    subscribeToChannel(channelId) {
      // Subscribe to PubNub
      if (!this.channels.includes(channelId)) {
        this.pubnub.subscribe({
          channels: [channelId],
          withPresence: true,
          uuid: this.userUUID,
        });
        this.channels.push(channelId);

        this.$nextTick(() => {
          this.fetchHistory(this.$store, channelId);
        });
      }
    },
    setCurrentChannel(value) {
      let conversation = this.conversations.find((c) => {
        return c.partnerCompany.id === value;
      });

      this.currentConversation = conversation;
      this.currentChannelId = this.currentConversation.capacity + "#" + value;
    },
    fetchHistory(store, channelId) {
      PubNubVue.getInstance().history(
        {
          channel: channelId,
          count: 100, // how many items to fetch
          stringifiedTimeToken: true, // false is the default
        },
        (_status, response) => {
          const msgs = response.messages;
          console.info("FRom PubNub: ", msgs);
          let historyToDisplay = msgs.map((msg) => {
            return msg.entry;
          });
          this.$set(this, "channelHistory", historyToDisplay);
          //debugger;
          // Iterate msgs array and save each element to history
          /*msgs.forEach((elem) => {
            store.commit("addHistory", { history: [elem] });
          });*/
        }
      );
    },
  },
  mounted() {
    this.pubnub = PubNubVue.getInstance();

    if (this.channelId != null && this.channelId.length > 1) {
      this.subscribeToChannel(this.channelId);
    }
  },
  unmount() {
    if (this.pubnub && this.pubnub != null) {
      this.pubnub.unsubscribe({
        channels: this.channels, //[this.channelId],
      });
    }
  },
};
</script>

<style scoped>
h1 {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.chat-container {
  display: table;
  max-width: 400px;
  min-width: 300px;
  width: 25%;
  height: 500px;
  margin: auto;
  background-color: #ffffff;
  border: solid 1px #bfbfbf;
  border-radius: 3px;
}

.heading {
  display: table;
  width: 100%;
  height: 28px;
  text-align: center;
  background-color: #484c68;
  border-radius: 3px 3px 0 0;
  -ms-user-select: none;
  user-select: none;
  /* background: linear-gradient(#ededed 0%, #cdcdcd 75%); */
}

.body {
  display: block;
  width: auto;
  height: inherit;
  overflow: hidden;
}

.table {
  display: table;
  width: 100%;
  height: 100%;
}
</style>
