<template>
  <div class="container-fluid px-0 px-sm-4 mx-auto">
    <div class="row justify-content-center mx-0">
      <div class="col-lg-10">
        <div class="card border-0">
          <form autocomplete="off">
            <div class="card-header bg-ligth">
              <div
                class="mx-0 mb-0 row justify-content-center text-center px-1"
              >
                <div class="col-md-2 col-4 my-1 px-2">
                  <div class="cell py-1" :class="{'select': isSameDay(new Date(value), today) }" @click="setDate(today.toISOString().substring(0,10))">Idag</div>
                </div>
                <div class="col-md-2 col-4 my-1 px-2">
                  <div class="cell py-1" :class="{'select': isSameDay(new Date(value), tomorrow) }" @click="setDate(tomorrow.toISOString().substring(0,10))">Imorgen</div>
                </div>
                <div class="col-md-2 col-4 my-1 px-2">
                  <div class="cell py-1" :class="{'select': isSameDay(new Date(value), overMorrow) }" @click="setDate(overMorrow.toISOString().substring(0,10))">
                    {{ lightFormat(overMorrow, "dd.MM.yyyy") }}
                  </div>
                </div>
                <div v-if="new Date(inputDate) > endOfDay(overMorrow)" class="col-md-2 col-4 my-1 px-2">
                  <div class="cell py-1" :class="{'select': isSameDay(new Date(value), new Date(inputDate))}" @click="setDate(inputDate)">
                    {{ lightFormat(new Date(inputDate), "dd.MM.yyyy") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-3 p-sm-5">
              <div class="row text-center mx-0">
                <div v-for="(timeStamp, index) in timeList" :key="'time_'+index" class="col-md-2 col-4 my-1 px-2">
                  <div class="cell py-1" :class="{'select': activeTime === timeStamp }" @click="setTime(timeStamp)">{{ timeStamp }}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lightFormat, parseISO, addDays, endOfDay, isSameDay, isToday } from "date-fns";

export default {
  name: "expire-date",
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      today: new Date(),
      tomorrow: addDays(new Date(), 1),
      overMorrow: addDays(new Date(), 2),
      inputDate: this.value,
      timeList: [
        "07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00",
        "14:30","15:00","15:30","16:00","16:30","17:00"
      ],
      timeListExtend: [],
      showListExtend: false,
      activeDay: this.value && this.value.indexOf("T") > 1 ? this.value.substring(0, this.value.indexOf("T")) : (new Date()).toISOString(),
      activeTime: this.value && this.value.indexOf("T") > 1 ? this.value.substring(11) : "12:00",
      added15: false,
      expireDate: this.value, //this.activeDay + "T" + this.activeTime,
    };
  },
  methods: {
    addDays,
    lightFormat,
    parseISO,
    endOfDay,
    isSameDay,
    isToday,
    setDate(newDay) {
      this.activeDay = newDay;
      this.expireDate = this.activeDay + "T" + this.activeTime;
      this.$emit("input", this.expireDate);
    },
    setTime(newTime) {
      this.activeTime = newTime;
      this.expireDate = this.activeDay + "T" + this.activeTime;
      this.$emit("input", this.expireDate);
    },
    async submit() {},
  },
};
</script>

<style lang="sass" scoped>
@import "@/sass/_variables.scss"
@import "./ExpireDate.scss"
</style>
