import Vue from "vue";

export const getGoogleAddress = () => {
  return Vue.axios.get(
    "/maps/api/place/textsearch/json?query=7172&fields=formatted_address&inputtype=textquery&key=AIzaSyAncmIvUEpz4rs3jNBbiIpBvS8ygie2TU0",
    {
      baseURL: "https://maps.googleapis.com",
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const getGooglePlace = () => {
  return Vue.axios.get(
    "/maps/api/place/details/json?place_id=ChIJq311jTi3RkYRP5qf7eYTypA&key=AIzaSyAncmIvUEpz4rs3jNBbiIpBvS8ygie2TU0&token=7400",
    {
      baseURL: "https://maps.googleapis.com",
      headers: { "Content-Type": "application/json" },
    }
  );
};

//https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJq311jTi3RkYRP5qf7eYTypA&key=AIzaSyDXjwrnQ6rPg7-lOgp63vBVD6ENkCUBF00
