import Vue from "vue";

export const getOfferList = () => {
  return Vue.axios.get("/api/offer", {
    headers: { "Content-Type": "application/json" },
  });
};

export const getOffer = (id) => {
  return Vue.axios.get("/api/offer/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

export const saveOffer = (data) => {
  return Vue.axios.post("/api/offer", JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteOffer = (id) => {
  return Vue.axios.delete("/api/offer/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};
