import { render, staticRenderFns } from "./ExpireDate.vue?vue&type=template&id=2f08d73e&scoped=true&"
import script from "./ExpireDate.vue?vue&type=script&lang=js&"
export * from "./ExpireDate.vue?vue&type=script&lang=js&"
import style0 from "./ExpireDate.vue?vue&type=style&index=0&id=2f08d73e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f08d73e",
  null
  
)

export default component.exports