import Vue from "vue";

export const getAgreementList = () => {
  return Vue.axios.get("/api/agreement", {
    headers: { "Content-Type": "application/json" },
  });
};

export const getAgreement = (id) => {
  return Vue.axios.get("/api/agreement/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};

export const saveAgreement = (data) => {
  return Vue.axios.post("/api/agreement", JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteAgreement = (id) => {
  return Vue.axios.delete("/api/agreement/" + id, {
    headers: { "Content-Type": "application/json" },
  });
};