import Geonames from "geonames.js";

export const getZipCode = async (place) => {
  const geonames = Geonames({
    username: "deliverit",
    lan: "no",
    encoding: "JSON"
  });
  const zipInfo = await geonames.postalCodeSearch({placename: place, country: "NO", maxRows: 5});

  return zipInfo && zipInfo.postalCodes && zipInfo.postalCodes!=null && zipInfo.postalCodes.length ? zipInfo.postalCodes[0].postalCode : "";
};
