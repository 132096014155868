<template>
  <div class="message-input">
    <textarea
      ref="messageInput"
      placeholder="message..."
      maxlength="20000"
      @keydown.enter="submitMessage"
    ></textarea>
  </div>
</template>

<script>
import { saveMessageToConversation } from "@/services/api/conversation";

export default {
  name: "message-input",
  props: {
    channelId: {
      type: String,
    },
    owner: {
      type: Boolean,
      required: false,
    },
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    saveMessageToConversation,
    async submitMessage(event) {
      if (!event.shiftKey) {
        event.preventDefault();
      } else {
        return;
      }

      // If the message body is empty, do not submit
      if (event.target.value.length === 0) {
        return;
      }

      const userUUID = this.$store.getters["user/chatUUID"];

      //Do we have a channel ?
      if (!this.channelId || this.channelId === "") {
        //TODO: let conversation = await saveConversation({})
      }

      // console.log(even.target.value);
      // Publish to PubNub the text and user's uuid
      this.publishNewMessage({
        channel: this.channelId,
        message: {
          text: event.target.value,
          uuid: userUUID,
        },
        owner: this.owner === true,
        fromCompany: this.$store.getters["account/companyId"],
        ownerCompany: this.company.id,
      });

      // Reset the text input
      event.target.value = "";
    },
    publishNewMessage(messageObject) {
      console.info(messageObject);
      this.$pnPublish(messageObject);

      this.saveMessageToConversation(messageObject);
    },
  },
};
</script>

<style scoped>
.message-input {
  display: table-row;
  width: 100%;
  height: 26px;
}

textarea {
  width: 98%;
  height: 30px;
  padding: 0 5px;
  margin: 0;
  box-sizing: border-box;
  line-height: 20pt;
  resize: none;
  outline: none;
  font-size: 14px;
  border: solid 1px #aaaaaa;
  border-radius: 3px;
  font-family: Helvetica;
}
</style>
