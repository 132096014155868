/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/store/index.js";
import { featureOptions } from "@/services/datasets";
import {
  BForm,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSpinner,
  BFormDatepicker,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { BIconX } from "bootstrap-vue";
//import { filter } from "core-js/core/array";

export default {
  name: "filter-form",
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "b-form": BForm,
    "b-input-group": BInputGroup,
    "b-form-input": BFormInput,
    "b-form-select": BFormSelect,
    "b-button": BButton,
    "b-spinner": BSpinner,
    "b-form-datepicker": BFormDatepicker,
    "b-form-checkbox-group": BFormCheckboxGroup,
    BIconX,
  },
  data() {
    return {
      loading: false,
      headerVerb: "Lag",
      //pickupDate: "",
      filterObject: {
        pickupDateFrom:
          this.filter && this.filter.pickupDateFrom
            ? this.filter.pickupDateFrom
            : "",
        pickupInDays:
          this.filter && this.filter.pickupInDays
            ? this.filter.pickupInDays
            : "",
        equipment:
          this.filter && this.filter.equipment ? this.filter.equipment : [],
        zipFrom: this.filter && this.filter.zipFrom ? this.filter.zipFrom : "",
        zipTo: this.filter && this.filter.zipTo ? this.filter.zipTo : "",
        zipCodes:
          this.filter && this.filter.zipCodes ? this.filter.zipCodes : [],
      },
      featureOptions: featureOptions,
    };
  },
  created() {
    //this.filterObject = this.$store.getters["filter/filterForm"];
    
    if (
      !this.filterObject ||
      this.filterObject === null ||
      this.filterObject === ""
    ) {
      /*
equipment: Array(0)
pickupDateFrom: ""
pickupInDays: ""
zipCodes: Array(0)
zipFrom: ""
zipTo: ""
      */
      this.headerVerb = "Endre";
    }
  },
  mounted() {
    //Set initial filter based on prop.filter
  },
  methods: {
    closeFilterForm(commit) {
      if (commit) {
        this.commitFilter();
      }
      this.$emit("close");
    },
    async commitFilter() {
      let currentFilter = {};
      if (
        this.filterObject.pickupDateFrom &&
        this.filterObject.pickupDateFrom != ""
      )
        currentFilter.pickupDateFrom = this.filterObject.pickupDateFrom;
      if (this.filterObject.equipment && this.filterObject.equipment.length > 0)
        currentFilter.equipment = this.filterObject.equipment.slice();
      if (
        this.filterObject.pickupInDays &&
        this.filterObject.pickupInDays != ""
      )
        currentFilter.pickupInDays = parseInt(this.filterObject.pickupInDays);

      if (
        this.filterObject.zipFrom &&
        this.filterObject.zipFrom != "" &&
        this.filterObject.zipTo &&
        this.filterObject.zipTo != ""
      ) {
        currentFilter.zipCodes = [
          {
            from: this.filterObject.zipFrom,
            to: this.filterObject.zipTo,
          },
        ];
      }
      await this.$store.dispatch("filter/commitFilter", currentFilter);
      this.$emit("update-filter", currentFilter);
    },
  },
  watch: {
    filter: {
      handler: function (newValue) {
        console.info("Filter form updated");
        if (this.$store && (!newValue.id || newValue.id === "")) {
          if (this.editMode) this.$store.dispatch("filter/storeForm", newValue);
        }
      },
      deep: true,
      immediate: false,
    },
    filterObject: {
      handler: async function (newValue) {
        if (newValue && newValue !== "" && newValue[0] === "{") {
          const obj = JSON.parse(newValue);
          // TODO: update this.form.  with new data...
          if (obj.id && obj.id !== "") {
            this.loading = true;
            let filter = await this.getFilter(obj.id);
            this.initFormObject(filter.data);
            this.loading = false;
          }
        }
      },
      immediate: true,
    },
  },
};
