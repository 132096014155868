/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/store/index.js";
//import Sidebar from "@/components/sidebarright/sidebarright.vue";
//import autosize from "autosize";
//import { gmapApi } from "vue2-google-maps";
//import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
import { required, decimal, numeric } from "vuelidate/lib/validators";
const clonedeep = require("lodash.clonedeep");
//import { mapFields } from "vuex-map-fields";
import { createHelpers } from "vuex-map-fields";
// `assignment` is the name of the Vuex module.
const { mapFields } = createHelpers({
  getterType: "capacity/getStructureField",
  mutationType: "capacity/updateStructureField",
});
//import FormGroup from "@/components/formgroup/formgroup.vue";
import { saveCapacity, getCapacity } from "@/services/api/capacity";
import { particlesSetup, styleMapSetup } from "@/utils/setup";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BInputGroup,
  BSpinner,
  BCollapse,
  BLink,
  BTabs,
  BTab,
  BSkeleton,
  BSkeletonWrapper,
  BInputGroupText,
  BInputGroupAppend,
  BFormSelect,
  BFormDatepicker,
  BFormRadioGroup,
} from "bootstrap-vue";
import { lightFormat, parseISO } from "date-fns";
import { getZipCode } from "@/services/api/geonames";

//import VueJsonPretty from "vue-json-pretty";

//import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "capacity-form",
  props: {
    editMode: {
      type: Boolean,
      default: true,
    },
    capacityShow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      isSending: false,
      profileSlideInOpen: false, // move to Vuex store later...
      trackingSlideInOpen: false, // move to Vuex store later...
      showOverlay: false,
      modalOpen: "",
      //modalSummaryShow: false,
      particlesSetup: particlesSetup,
      styleMapSetup: styleMapSetup,
      loadingHits: true,
      editModeState: false,
      selectedSizeUnit: "lhw",
      sizeUnitGroups: [
        { text: "L B H", value: "lhw" },
        { text: "Kubikk", value: "m3" },
        { text: "Lastemeter", value: "loadmeter" },
      ],
      autoCompleteRestrictions: {
        componentRestrictions: { country: ["no", "se", "dk", "fi"] },
      },
      addressDest: "",
      volumeTabIndex: 0,
      form: {
        id: "",
        title: "",
        weight: "",
        height: "",
        length: "",
        width: "",
        volume: "",
        selectedFeature: [],
        selectedEquipment: [],
        equipmentOther: "",
        address: "",
        addressDest: "",
        addressObj: {},
        addressDestObj: {},
        startDate: "",
        vehicleType: "",
        shortDescription: "",
        companyId: "",
        categoryId: "",
        status: 0,
      },
      loadMeter: "",
      categoryId: "",
      companyId: "",
      showAgreement: false,
      hasImage: false,
      formSummary: {},
      featureOptions: [
        {
          text: this.$i18n.t("capacity.feature.fridge"),
          value: "fridge",
        },
        {
          text: this.$i18n.t("capacity.feature.cooler"),
          value: "cooler",
        },
        {
          text: this.$i18n.t("capacity.feature.adr"),
          value: "adr",
        },
        {
          text: this.$i18n.t("capacity.feature.kapell"),
          value: "kapell",
        },
        {
          text: this.$i18n.t("capacity.feature.sideopen"),
          value: "sideopen",
        },
      ],
      equipmentOptions: [
        {
          text: this.$i18n.t("capacity.equipment.forklift"),
          value: "forklift",
        },
        {
          text: this.$i18n.t("capacity.equipment.liftcrane"),
          value: "liftcrane",
        },
        {
          text: this.$i18n.t("capacity.equipment.loadingramp"),
          value: "loadingramp",
        },
        {
          text: this.$i18n.t("capacity.equipment.liftinglimb"),
          value: "liftinglimb",
        },
        {
          text: this.$i18n.t("capacity.equipment.other"),
          value: "other",
        },
      ],
      vehicleOptions: [
        {
          text: this.$i18n.t("global.vehicletype.placeholder"),
          value: "",
        },
        {
          text: this.$i18n.t("global.vehicletype.truck"),
          value: "truck",
        },
        {
          text: this.$i18n.t("global.vehicletype.truck_sideopen"),
          value: "truck_sideopen",
        },
        {
          text: this.$i18n.t("global.vehicletype.semi_open"),
          value: "semi_open",
        },
        {
          text: this.$i18n.t("global.vehicletype.semi_sideopen"),
          value: "semi_sideopen",
        },
        {
          text: this.$i18n.t("global.vehicletype.8_palletts"),
          value: "8_palletts",
        },
        {
          text: this.$i18n.t("global.vehicletype.van"),
          value: "van",
        },
      ],
    };
  },
  validations: {
    form: {
      address: { required },
      addressDest: { required },
      //weight: { required, decimal },
      //volume: { decimal },
      //height: { required, decimal },
      length: { required, decimal },
      //width: { required, decimal },
    },
  },
  components: {
    "b-form": BForm,
    "b-form-group": BFormGroup,
    "b-form-input": BFormInput,
    "b-form-checkbox": BFormCheckbox,
    "b-button": BButton,
    "b-form-textarea": BFormTextarea,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-input-group": BInputGroup,
    "b-spinner": BSpinner,
    "b-collapse": BCollapse,
    "b-link": BLink,
    "b-tabs": BTabs,
    "b-tab": BTab,
    "b-skeleton": BSkeleton,
    "b-skeleton-wrapper": BSkeletonWrapper,
    "b-input-group-append": BInputGroupAppend,
    "b-input-group-text": BInputGroupText,
    "b-form-select": BFormSelect,
    "b-form-datepicker": BFormDatepicker,
    "b-form-radio-group": BFormRadioGroup,
    //FormGroup
  },
  i18n: {
    /* messages: {
        en: {"tr": i18nCodes_en},
        no: {"tr": i18nCodes_no}
      }  */
  },
  created() {
    console.info(
      "CREATED assignment form"
      //,this.$store.getters["assignment/assignmentForm"]
    );
    if (
      this.editMode &&
      (!this.capacityObject ||
        this.capacityObject === null ||
        this.capacityObject === "")
    ) {
      this.form = this.$store.getters["capacity/capacityForm"];
    }
  },
  mounted() {
    if (typeof this.form === "undefined") this.initFormObject();

    console.info("Mounted Capacity:", this.form.address); //, this.$refs["shortDescription"]);
    // autosize(this.$refs["shortDescription"]);

    if (this.form && this.form.address && this.$refs.autoCompleteFrom)
      this.$refs.autoCompleteFrom.$refs.input.value = this.form.address;
    if (this.form && this.form.addressDest && this.$refs.autoCompleteDest)
      this.$refs.autoCompleteDest.$refs.input.value = this.form.addressDest;
  },
  watch: {
    $route(to, from) {
      // Call resizePreserveCenter() on all maps
      //Vue.$gmapDefaultResizeBus.$emit("resize");
    },
    form: {
      handler: function (newValue) {
        console.info("Form updated");
        if (this.$store && (!newValue.id || newValue.id === "")) {
          if (this.editMode)
            this.$store.dispatch("capacity/storeForm", newValue);
        }
      },
      deep: true,
      immediate: false,
    },
    loadMeter: {
      handler: function (newValue) {
        let tmpValue = parseFloat(newValue) * 10;
        this.form.length = tmpValue.toLocaleString();
        console.info(
          "New Lenegth: ",
          tmpValue,
          this.form.length,
          tmpValue.toLocaleString()
        );
      },
      immediate: false,
    },
    capacityObject: {
      handler: async function (newValue) {
        if (newValue && newValue !== "" && newValue[0] === "{") {
          const obj = JSON.parse(newValue);
          // TODO: update this.form.  with new data...
          if (obj.id && obj.id !== "") {
            this.loading = true;
            let capacity = await this.getCapacity(obj.id);
            this.initFormObject(capacity.data);
            this.loading = false;

            //console.info(capacity.data);
            this.$emit("changed-conversations", capacity.data.conversations);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    //google: gmapApi,
    //...mapFields(["capacity"]), //.title", "form.weight"]),
    id: () => {
      return this.$route.params.id;
    },
    editing() {
      return (
        (this.editMode === true || this.editModeState === true) &&
        this.loading === false
      );
    },
    capacityObject() {
      return this.capacityShow === null || this.capacityShow === ""
        ? ""
        : JSON.stringify(this.capacityShow);
    },
    displayVehicleType() {
      if (!this.form.vehicleType || this.form.vehicleType === "") return "";
      let vehicle = this.vehicleOptions.find((option) => {
        return option.value === this.form.vehicleType;
      });
      return vehicle ? vehicle.text : "";
    },
    canEdit() {
      return this.form.companyId === this.$store.getters["account/companyId"];
    },
  },
  methods: {
    initFormObject: function (capacity) {
      this.form = {
        id: capacity ? capacity.id ?? "" : "",
        title: capacity ? capacity.title ?? "" : "",
        weight:
          capacity && capacity.capacity
            ? capacity.capacity[0].weight ?? ""
            : "",
        height:
          capacity && capacity.capacity
            ? capacity.capacity[0].height ?? ""
            : "",
        length:
          capacity && capacity.capacity
            ? capacity.capacity[0].length ?? ""
            : "",
        width:
          capacity && capacity.capacity ? capacity.capacity[0].width ?? "" : "",
        volume: capacity ? capacity.capacity[0].volume ?? "" : "",
        selectedFeature: capacity ? capacity.selectedFeature ?? [] : [], // Must be an array reference!
        selectedEquipment: capacity
          ? capacity.selectedEquipmentPickup ?? []
          : [], // Must be an array reference!
        equipmentOther: capacity ? capacity.equipmentOtherPickup ?? "" : "",
        address:
          capacity && capacity.addressPickup
            ? capacity.addressPickup.addressLine ?? ""
            : "",
        addressDest:
          capacity && capacity.addressDestination
            ? capacity.addressDestination.addressLine ?? ""
            : "",
        addressObj:
          capacity && capacity.addressPickup ? capacity.addressPickup : {},
        addressDestObj:
          capacity && capacity.addressDestination
            ? capacity.addressDestination
            : {},
        shortDescription: capacity ? capacity.shortDescription ?? "" : "",
        startDate: capacity ? capacity.pickupDate ?? "" : "",
        //pickup1Time: capacity ? capacity.pickupTime ?? "" : "",
        vehicleType: capacity ? capacity.vehicleType ?? "" : "",
        status: capacity ? capacity.status ?? 10 : 10,
        type: capacity ? capacity.type ?? 1 : 1,
        companyId: capacity && capacity.company ? capacity.company.id : null,
        company: capacity ? capacity.company ?? null : null,
      };
      this.selectedSizeUnit =
        capacity &&
        capacity.selectedSizeUnit &&
        capacity.selectedSizeUnit !== ""
          ? capacity.selectedSizeUnit
          : this.sizeUnitGroups[0].value;
      this.loadMeter = (parseFloat(this.form.length) / 10).toLocaleString();
    },
    saveCapacity,
    getCapacity,
    lightFormat,
    parseISO,
    getZipCode,
    setEditMode: function () {
      this.$emit("toggleEditing");
      this.editModeState = true;
      this.$nextTick(() => {
        if (this.form && this.form.address && this.$refs.autoCompleteFrom)
          this.$refs.autoCompleteFrom.$refs.input.value = this.form.address;
        if (this.form && this.form.addressDest && this.$refs.autoCompleteDest)
          this.$refs.autoCompleteDest.$refs.input.value = this.form.addressDest;
      });
    },
    openAgreement: function () {
      this.$emit("open-agreement", true);
    },
    getCurrentCompanyId: function () {
      return store.getters &&
        store.getters["account/companyId"] &&
        store.getters["account/companyId"] !== ""
        ? store.getters["account/companyId"]
        : this.companyId && this.companyId != ""
        ? this.companyId
        : "5d658725faa99c2bf4a84eb5";
    },
    getAddressData: async function (addressData) {
      console.info("From AutoCompl FROM: ", addressData);
      let googlePostalCode = "";

      if (addressData.address_components) {
        for (var i = 0; i < addressData.address_components.length; i++) {
          for (
            var j = 0;
            j < addressData.address_components[i].types.length;
            j++
          ) {
            if (addressData.address_components[i].types[j] == "postal_code") {
              googlePostalCode = addressData.address_components[i].long_name;
            }
          }
        }
      }
      this.form.address = addressData.formatted_address || addressData.name;
      if(!googlePostalCode || googlePostalCode == null) {
        googlePostalCode = await getZipCode(this.form.address);
      }

      this.$set(this.form, "addressObj", {
        geometry: addressData.geometry,
        zipCode: googlePostalCode,
        placeId: addressData.place_id,
      });

      /* this.center = {
        lat: addressData.geometry.location.lat(),
        lng: addressData.geometry.location.lng(),
      };
      this.markers = [
        {
          position: {
            lat: addressData.geometry.location.lat(),
            lng: addressData.geometry.location.lng(),
          },
        },
      ];*/
    },
    getAddressDataDest: async function (addressData) {
      console.info("From AUtoCompl DEST: ", addressData);
      let googlePostalCode = "";

      if (addressData.address_components) {
        for (var i = 0; i < addressData.address_components.length; i++) {
          for (
            var j = 0;
            j < addressData.address_components[i].types.length;
            j++
          ) {
            if (addressData.address_components[i].types[j] == "postal_code") {
              googlePostalCode = addressData.address_components[i].long_name;
            }
          }
        }
      }
      this.form.addressDest = addressData.formatted_address || addressData.name;
      if(!googlePostalCode || googlePostalCode == null) {
        googlePostalCode = await getZipCode(this.form.addressDest);
      }

      this.$set(this.form, "addressDestObj", {
        geometry: addressData.geometry,
        zipCode: googlePostalCode,
        placeId: addressData.place_id,
      });

      console.info("ADDRESS DEST: ", this.form.addressDestObj);
      /* this.centerDest = {
        lat: addressData.geometry.location.lat(),
        lng: addressData.geometry.location.lng(),
      };
      this.markersDest = [
        {
          position: {
            lat: addressData.geometry.location.lat(),
            lng: addressData.geometry.location.lng(),
          },
          icon: "/img/marker-green.png",
        },
      ]; */
    },
    tabLinkClass(idx) {
      if (this.volumeTabIndex === idx) {
        return ["bg-bluegrey", "text-light"];
      } else {
        return ["bg-lightgrey", "text-dark"];
      }
    },
    dateDisabledFrom(ymd, date) {
      return false;
    },
    recaptcha() {
      this.$recaptcha("submit_capacity").then((token) => {
        console.info("reCAPTCHA token: ", token); // Will print the token
      });
    },
    resetForm() {
      store.dispatch("capacity/resetForm");
      this.initFormObject();
    },
    validate() {
      this.$v.form.$touch();
    },
    submit(event, newStatus, closeModal) {
      // First CHECK ReCAPTCHA V3

      //this.recaptcha();

      console.info("Submitting now...?", this.form);
      this.isSending = true;
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) {
        console.info("Pending or Error: ", this.$v.form.$error);

        this.isSending = false;
        return;
      }
      // to form submit after this
      let toServer = {};
      if (this.form.id && this.form.id !== "") toServer.id = this.form.id;
      toServer.title = this.form.title;
      toServer.weight = this.form.weight;
      toServer.height = this.form.height;
      toServer.length = this.form.length;
      toServer.width = this.form.width;
      toServer.volume = this.form.volume;
      toServer.selectedFeature = this.form.selectedFeature;
      toServer.selectedEquipment = this.form.selectedEquipment;
      toServer.equipmentOther = this.form.equipmentOther;
      toServer.address = this.form.address;
      toServer.addressDest = this.form.addressDest;
      toServer.addressObj = this.form.addressObj;
      toServer.addressDestObj = this.form.addressDestObj;
      toServer.shortDescription = this.form.shortDescription;
      toServer.startDate = this.form.startDate;
      toServer.vehicleType = this.form.vehicleType;
      toServer.categoryId = this.categoryId === "" ? null : this.categoryId;
      toServer.companyId = this.getCurrentCompanyId();
      toServer.status =
        typeof newStatus !== "undefined" && newStatus != null
          ? parseInt(newStatus, 10)
          : this.form.status ?? 10;
      toServer.selectedSizeUnit = this.selectedSizeUnit;
      toServer.type = 1; //"offer";

      this.saveCapacity(toServer) //JSON.stringify(toServer)
        .then((response) => {
          console.info("OK", response.data);
          //this.resetFormdata(response);
          this.form.id = response.data.id;
          this.$noty.success(
            this.$i18n.t("assignment.messages.onSuccessfulSent")
          );

          this.$store.dispatch("capacity/resetForm");
          this.$emit("capacity-saved", response.data);
          this.isSending = false;
          if (closeModal === true) this.$emit("close-modal", true);
        })
        .catch((error) => {
          console.info("ERROR", error);
          this.$noty.info(
            "Noe gikk galt ved registrering av oppdraget ... mer info..."
          );
          this.isSending = false;
        });

      console.info("Submitting this : ", toServer);
    },
  },
};
