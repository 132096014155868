import i18n from "@/i18n";

export const extractNumberfromLength = (length) => {
  let match =
    length && length !== null
      ? length.match(/^(\d+(?:\.\d+)?)\s?c?m$/)
      : undefined;
  return match ? match[1] : null;
};

export const extractNumberfromWeight = (weight) => {
  let match =
    weight && weight !== null
      ? weight.match(/^(\d+(?:\.\d+)?)\s?k?g$/)
      : undefined;
  return match ? match[1] : null;
};

export const formatWithUnit = (
  value,
  unit,
  placeholder,
  converter,
  decimals
) => {
  if (typeof value === "undefined" || value === "" || value == null)
    return placeholder;
  if (converter) value = converter(value);
  if (!decimals && value < 1) decimals = 2;
  if (!decimals && value < 10) decimals = 1;

  return Number(value).toFixed(decimals ?? 2) + "" + unit;
};

export const getVolumeWeight = (packageList) => {
  console.info("getVolumeWeight");
  let volumeIndex = 0;
  if (!packageList) packageList = [];

  packageList.forEach((colli) => {
    if (colli.length + colli.width + colli.height != "") {
      try {
        volumeIndex +=
          (Number(colli.length) * Number(colli.width) * Number(colli.height)) /
          3500;
      } catch (e) {
        console.info("Colli measures are not all numbers");
      }
    }
  });

  return volumeIndex;
};

export const stringIsNullOrEmpty = (value) => {
  return !value || value == null || value.trim() === "";
};

export const vehicleOptions = [
  {
    text: i18n.t("global.vehicletype.placeholder"),
    value: "",
    url: "",
  },
  {
    text: i18n.t("global.vehicletype.truck"),
    value: "truck",
    url: "/img/truck.png",
  },
  {
    text: i18n.t("global.vehicletype.truck_sideopen"),
    value: "truck_sideopen",
    url: "/img/truck.png",
  },
  {
    text: i18n.t("global.vehicletype.semi_open"),
    value: "semi_open",
    url: "/img/temp/semi-trailer-truck.png",
  },
  {
    text: i18n.t("global.vehicletype.semi_sideopen"),
    value: "semi_sideopen",
    url: "/img/temp/semi-trailer-truck.png",
  },
  {
    text: i18n.t("global.vehicletype.8_palletts"),
    value: "8_palletts",
    url: "/img/truck.png",
  },
  {
    text: i18n.t("global.vehicletype.van"),
    value: "van",
    url: "/img/truck.png",
  },
];
