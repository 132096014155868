/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/store/index.js";
import { required, decimal, numeric } from "vuelidate/lib/validators";
import { saveOffer } from "@/services/api/offer";
import { getCompanyList } from "@/services/api/company";
import { getCapacity } from "@/services/api/capacity";

import {
  BForm,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  name: "offer-form",
  props: {
    capacityId: {
      type: String,
      required: false,
      default: "",
    },
    capacityObject: {
      type: Object,
      required: false,
      default: null,
    },
    owner: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "b-form": BForm,
    "b-input-group": BInputGroup,
    "b-form-input": BFormInput,
    "b-form-select": BFormSelect,
    "b-button": BButton,
    "b-spinner": BSpinner,
    "b-form-textarea": BFormTextarea,
  },
  data() {
    return {
      loading: false,
      isSending: false,
      partnerCompanyList: [],
      form: {
        id: "",
        amount: null,
        partnerCompany:
          this.owner === true ? "" : store.getters["account/companyId"],
        status: 10,
        partnerCapacity: null,
        description: "",
      },
      validationText: "",
      capacity: null,
    };
  },
  validations: {
    form: {
      partnerCompany: { required },
      amount: { required },
    },
  },
  async mounted() {
    //TODO: Get companyList from Vuex ? Or Redis backend ?
    const companyList = await this.getCompanyList("status", 10);

    this.partnerCompanyList =
      companyList.data &&
      companyList.data.map((company) => {
        return {
          text: company.name,
          value: company._id,
        };
      });

    /*debugger;
    this.form.partnerCompany = this.owner === true ? "" : store.getters["account/companyId"];
     if (typeof this.form === "undefined") {
      this.form = {
        
      };
    } */
  },
  watch: {
    capacityObject: function (newValue, _oldValue) {
      if (newValue !== null) {
        const companyWithConversations = newValue.conversations.map(
          (conversation) => {
            return {
              text: conversation.partnerCompany.name,
              value: conversation.partnerCompany.id,
            };
          }
        );
        if (companyWithConversations.length > 0) {
          this.partnerCompanyList.unshift(...companyWithConversations);
        }
      }
    },
    validationText: function (newValue, oldValue) {
      this.$emit(
        "formReadyForSubmit",
        newValue === "" && this.form.amount !== null && this.form.amount !== ""
      );
    },
  },
  methods: {
    getCompanyList,
    getCapacity,
    validate() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) {
        console.info("Pending or Error: ", this.$v.form);
        this.isSending = false;
        this.validationText = "Feil";
        return;
      }
      this.validationText = "";
      this.submit(false);
    },
    handleInputChange() {
      if (this.validationText !== "") {
        this.validate();
      }
    },
    saveOffer,
    submit(validate) {
      this.isSending = true;
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if ((validate == true && this.$v.form.$pending) || this.$v.form.$error) {
        console.info("Pending or Error: ", this.$v.form.$error);

        this.isSending = false;
        return;
      }
      // to form submit after this
      let toServer = {};
      if (this.form.id && this.form.id !== "") toServer.id = this.form.id;
      toServer.partnerCompany = this.form.partnerCompany;
      toServer.amount = this.form.amount;
      toServer.capacity =
        this.capacityId && this.capacityId != ""
          ? this.capacityId
          : this.capacityObject.id;
      toServer.description = this.form.description;

      this.saveOffer(toServer) //JSON.stringify(toServer)
        .then(async (response) => {
          console.info("OK", response.data);
          //this.resetFormdata(response);
          this.form.id = response.data.id;
          this.$noty.success(this.$i18n.t("offer.messages.onSuccessfulSent"));

          if (this.capacityObject && this.capacityObject.id) {
            const tmpcapacity = await this.getCapacity(this.capacityObject.id);
            this.capacity = tmpcapacity.data;
          }
          console.info("NEW:", this.capacity);
          this.$emit("close-modal", this.capacity);

          this.isSending = false;
        })
        .catch((error) => {
          console.info("ERROR", error);
          this.$noty.info(
            "Noe gikk galt ved registrering av tilbudet ... mer info..."
          );
          this.isSending = false;
        });
    },
  },
};
