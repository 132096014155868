/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/store/index.js";
import { required, decimal, numeric } from "vuelidate/lib/validators";
import { saveAgreement } from "@/services/api/agreement";
import { getCompanyList } from "@/services/api/company";
import { getCapacity } from "@/services/api/capacity";
import { vehicleOptions, formatWithUnit } from "@/utils";

import {
  BForm,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "agreement-form",
  props: {
    capacityId: {
      type: String,
      required: false,
      default: "",
    },
    capacityObject: {
      type: Object,
      required: false,
      default: null,
    },
    owner: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "b-form": BForm,
    "b-input-group": BInputGroup,
    "b-form-input": BFormInput,
    "b-form-select": BFormSelect,
    "b-button": BButton,
    "b-spinner": BSpinner,
  },
  data() {
    return {
      loading: false,
      isSending: false,
      partnerCompanyList: [],
      form: {
        id: "",
        amount: null,
        partnerCompany: "",
      },
      validationText: "",
      capacity: null,
    };
  },
  validations: {
    form: {
      partnerCompany: { required },
      amount: { required, numeric },
    },
  },
  computed: {
    displayVehicleType() {
      if (!this.capacity.vehicleType || this.capacity.vehicleType === "") return "";
      let vehicle = vehicleOptions.find((option) => {
        return option.value === this.capacity.vehicleType;
      });
      return vehicle ? vehicle.text : "";
    },
  },
  async mounted() {
    //TODO: Get companyList from Vuex ? Or Redis backend ?
    this.loading = true;
    const companyList = await this.getCompanyList("status", 10);

    this.partnerCompanyList =
      companyList.data &&
      companyList.data.map((company) => {
        return {
          text: company.name,
          value: company._id,
        };
      });

    if (
      this.capacityObject !== null &&
      this.capacityObject.conversations &&
      this.capacityObject.conversations.length > 0
    ) {
      const companyWithConversations = this.capacityObject.conversations.map(
        (conversation) => {
          return {
            text: conversation.partnerCompany.name,
            value: conversation.partnerCompany.id,
          };
        }
      );
      const companyWithOffer = this.capacityObject.offers.map((offer) => {
        return {
          text: offer.partnerCompany.name,
          value: offer.partnerCompany.id,
        };
      });
      if (companyWithConversations.length > 0 || companyWithOffer.length > 0) {
        this.partnerCompanyList.unshift(
          ...companyWithConversations,
          ...companyWithOffer
        );

        /* Get unique elements:
        this.partnerCompanyList = [
          ...new Map(
            this.partnerCompanyList.map((item) => [item["id"], item])
          ).values(),
        ]; */
      }
    }
    if (this.capacityObject && this.capacityObject.id) {
      const tmpcapacity = await this.getCapacity(this.capacityObject.id);
      this.capacity = tmpcapacity.data;
    }
    this.loading = false;
  },
  watch: {
    capacityObject: function (newValue, _oldValue) {
      if (newValue !== null) {
        const companyWithConversations = newValue.conversations.map(
          (conversation) => {
            return {
              text: conversation.partnerCompany.name,
              value: conversation.partnerCompany.id,
            };
          }
        );
        if (companyWithConversations.length > 0) {
          this.partnerCompanyList.unshift(...companyWithConversations);
        }
      }
    },
    validationText: function (newValue, oldValue) {
      this.$emit(
        "formReadyForSubmit",
        newValue === "" && this.form.amount !== null && this.form.amount !== ""
      );
    },
  },
  methods: {
    getCompanyList,
    getCapacity,
    formatWithUnit,
    validate() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) {
        console.info("Pending or Error: ", this.$v.form);
        this.isSending = false;
        this.validationText = "Feil. Både firma og beløp må fylles ut.";
        return;
      }
      this.validationText = "";
      this.submit(false);
    },
    handleInputChange() {
      if (this.validationText !== "") {
        this.validate();
      }
    },
    saveAgreement,
    submit(validate) {
      this.isSending = true;
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if ((validate == true && this.$v.form.$pending) || this.$v.form.$error) {
        console.info("Pending or Error: ", this.$v.form.$error);
        this.validationText = "Feil. Både firma og beløp må fylles ut.";
        this.isSending = false;
        return;
      }
      // to form submit after this
      this.validationText = "";
      let toServer = {};
      if (this.form.id && this.form.id !== "") toServer.id = this.form.id;
      toServer.partnerCompany = this.form.partnerCompany;
      toServer.amount = this.form.amount;
      toServer.capacity =
        this.capacityId && this.capacityId != ""
          ? this.capacityId
          : this.capacityObject.id;

      this.saveAgreement(toServer) //JSON.stringify(toServer)
        .then((response) => {
          console.info("OK", response.data);
          //this.resetFormdata(response);
          this.form.id = response.data.id;
          this.$noty.success(
            this.$i18n.t("agreement.messages.onSuccessfulSent")
          );

          if (this.capacity.agreements == null) this.capacity.agreements = [];
          this.capacity.agreements.push(response.data._id);
          this.capacity.status = 50;
          console.info("NEW:", this.capacity);
          this.$emit("close-modal", this.capacity);
        })
        .catch((error) => {
          console.info("ERROR", error);
          this.$noty.info("Noe gikk galt ved registrering av avtalen.");
          this.isSending = false;
        });
    },
  },
};
