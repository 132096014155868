/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/store/index.js";
import { getCompanyList } from "@/services/api/company";

import {
  BForm,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  name: "offer-form",
  props: {
    capacityObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    "b-form": BForm,
    "b-input-group": BInputGroup,
    "b-form-input": BFormInput,
    "b-form-select": BFormSelect,
    "b-button": BButton,
    "b-spinner": BSpinner,
    "b-form-textarea": BFormTextarea,
  },
  data() {
    return {
      loading: false,
      isSending: false,
      partnerCompanyList: [],
    };
  },
  async mounted() {
    //TODO: Get companyList from Vuex ? Or Redis backend ?
    const companyList = await this.getCompanyList("status", 10);

    this.partnerCompanyList =
      companyList.data &&
      companyList.data.map((company) => {
        return {
          text: company.name,
          value: company._id,
        };
      });

    /*debugger;
    this.form.partnerCompany = this.owner === true ? "" : store.getters["account/companyId"];
     if (typeof this.form === "undefined") {
      this.form = {
        
      };
    } */
  },
  watch: {
    capacityObject: function (newValue, _oldValue) {
      if (newValue !== null) {
        const companyWithConversations = newValue.conversations.map(
          (conversation) => {
            return {
              text: conversation.partnerCompany.name,
              value: conversation.partnerCompany.id,
            };
          }
        );
        if (companyWithConversations.length > 0) {
          this.partnerCompanyList.unshift(...companyWithConversations);
        }
      }
    },
  },
  methods: {
    getCompanyList,
  },
};
