<template>
  <div class="chat-log" ref="chatLogContainer">
    <template v-if="!vueChatMsg || vueChatMsg.length === 0">
      <message-bubble
        v-for="(historyMsg, idx) in channelHistory"
        v-bind:key="historyMsg.id ? historyMsg.id : 'chathistory_' + idx"
        v-bind:uuid="historyMsg.uuid"
        v-bind:text="historyMsg.text"
      ></message-bubble>
    </template>

    <message-bubble
      v-for="msg in vueChatMsg"
      v-bind:key="msg.id"
      v-bind:uuid="msg.message.uuid"
      v-bind:text="msg.message.text"
    ></message-bubble>
  </div>
</template>

<script>
import MessageBubble from "@/components/chat/MessageBubble";
//import { mapGetters } from "vuex";

/**
 * Auto scrolls the chat log to the bottom when a new message is received
 */
function scrollBottom() {
  this.$el.scrollTo(0, this.$el.scrollHeight);
}

export default {
  name: "chat-log",
  props: {
    channelId: {
      type: String,
    },
    channelHistory: {
      type: Array,
    },
  },
  components: { MessageBubble },
  data() {
    return {
      /*
       * $pnGetMessage will listen to a channel subscribed to and start to
       * display messages as soon as they are received.
       */

      vueChatMsg: this.$pnGetMessage(this.channelId),
      history: [],
    };
  },
  watch: {
    vueChatMsg: function () {
      this.$nextTick(scrollBottom);
    },
    channelId: function (newChannel) {
      console.info("New channel", newChannel);
      //this.$set(this, "vueChatMsg", this.$pnGetMessage(newChannel));
      this.vueChatMsg = this.$pnGetMessage(newChannel);
      this.$nextTick(scrollBottom);
    },
  },
  mounted() {
    console.info("Channel", this.channelId);
    if (this.channelId && this.channelId !== "") {
      //this.$set(this, "vueChatMsg", this.$pnGetMessage(this.channelId));
    }
  },
};
</script>

<style scoped>
.chat-log {
  display: block;
  height: 58ch;
  width: 100%;
  padding: 2% 4%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.chat-log::-webkit-scrollbar {
  display: none;
}
</style>
